<template lang="pug">
template(v-if='updateRoute')
  NuxtLink(:to='routeObj')
    slot

template(v-else)
  button(type='button' @click='updatePage')
    slot
</template>

<script setup lang="ts">
const route = useRoute()
const $searchContext = inject('$searchContext', null)

const { pageNumber, updateRoute = true } = defineProps<{
  pageNumber: number
  updateRoute?: boolean
}>()

const routeObj = computed(() => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { page, ...restOfQuery } = route.query

  return {
    name: route.name,
    params: {
      ...route.params,
    },
    query: {
      ...restOfQuery,
      ...(pageNumber > 1 && { page: pageNumber }),
    },
  }
})

function updatePage() {
  // Update the page without impacting the URL
  $searchContext.setPage(pageNumber)
  $searchContext.search()
}
</script>
