<template lang="pug">
div(v-if='totalPages > 1')
  ul(class='flex items-center justify-center md:justify-end')
    li(v-if='currentPage > 1' class='mr-1')
      AlgoliaPaginationLink(
        :page-number='currentPage - 1'
        :update-route='updateRoute'
        class='flex justify-center items-center text px-3 w-10 h-10 bg-white border rounded md:w-auto'
        aria-label='Previous Page'
      )
        img(src='@/assets/arrow.svg' class='w-3 transform rotate-90' height='12' width='12' alt='Previous Page')
        span(class='hidden lg:block ml-2') Prev

    template(v-for='item in items')
      template(v-if='item === "ellipsis"')
        li(:key='item')
          div(class='flex justify-center items-end')
            | &hellip;

      template(v-else)
        li(:key='item' class='mx-1 md:last:mr-0')
          AlgoliaPaginationLink(
            :page-number='item'
            :update-route='updateRoute'
            class='flex justify-center items-center text p-2 rounded md:w-10 md:h-10 md:hover:border md:border-gray-dark md:hover:no-underline'
            :class='{ "or-pagination-active font-bold md:bg-primary": item === currentPage }'
          )
            | {{ item }}

    li(v-if='currentPage < totalPages' class='ml-1')
      AlgoliaPaginationLink(
        :page-number='currentPage + 1'
        :update-route='updateRoute'
        class='flex justify-center items-center text px-3 w-10 h-10 bg-white border rounded md:w-auto'
        aria-label='Next Page'
      )
        span(class='hidden lg:block mr-2') Next
        img(src='@/assets/arrow.svg' class='w-3 transform -rotate-90' height='12' width='12' alt='Next Page')
</template>
<script setup lang="ts">
const $searchContext = inject('$searchContext', null)

const { updateRoute = true } = defineProps<{
  updateRoute?: boolean
}>()

const currentPage = computed(() => {
  return $searchContext.state.value.currentPage
})

const totalPages = computed(() => {
  return $searchContext.state.value.totalPages
})

const rangeStart = computed(() => {
  let startPage = 2

  if (totalPages.value > 7 && currentPage.value > 4) {
    if (currentPage.value > totalPages.value - 4) startPage = totalPages.value - 4
    else startPage = currentPage.value - 1
  }

  return startPage
})

const rangeEnd = computed(() => {
  let endPage = totalPages.value - 1

  if (totalPages.value > 7) {
    if (currentPage.value < 5) endPage = 5
    else if (currentPage.value < totalPages.value - 3) endPage = currentPage.value + 1
  }

  return endPage
})

const items = computed(() => {
  const range = []

  for (let i = rangeStart.value; i <= rangeEnd.value; i++) range.push(i)

  return [
    1,
    ...(rangeStart.value > 2 ? ['ellipsis'] : []),
    ...range,
    ...(totalPages.value - rangeEnd.value > 1 ? ['ellipsis'] : []),
    totalPages.value,
  ]
})

const limitedPages = computed(() => {
  const pages = []
  let startPage = 2
  let endPage = totalPages.value - 1

  if (totalPages.value > 8) {
    if (currentPage.value < 5) endPage = 5
    else if (currentPage.value > totalPages.value - 4) startPage = totalPages.value - 4
    else {
      startPage = currentPage.value - 1
      endPage = currentPage.value + 1
    }
  }

  for (let i = startPage; i <= endPage; i++) {
    pages.push(i)
  }

  return pages
})
</script>
